export const municipalities = [
    "100 Mile House",
    "Abbotsford",
    "Alert Bay",
    "Anmore",
    "Armstrong",
    "Ashcroft",
    "Barriere",
    "Belcarra",
    "Bowen Island",
    "Burnaby",
    "Burns Lake",
    "Cache Creek",
    "Campbell River",
    "Canal Flats",
    "Castlegar",
    "Central Saanich",
    "Chase",
    "Chetwynd",
    "Chilliwack",
    "Clearwater",
    "Clinton",
    "Coldstream",
    "Colwood",
    "Comox",
    "Coquitlam",
    "Courtenay",
    "Cranbrook",
    "Creston",
    "Cumberland",
    "Daajing Giids",
    "Dawson Creek",
    "Delta",
    "Duncan",
    "Elkford",
    "Enderby",
    "Esquimalt",
    "Fernie",
    "Fort St James",
    "Fort St John",
    "Fraser Lake",
    "Fruitvale",
    "Gibsons",
    "Gold River",
    "Golden",
    "Grand Forks",
    "Granisle",
    "Greenwood",
    "Harrison Hot Springs",
    "Hazelton",
    "Highlands",
    "Hope",
    "Houston",
    "Hudson's Hope",
    "Invermere",
    "Kamloops",
    "Kaslo",
    "Kelowna",
    "Kent",
    "Keremeos",
    "Kimberley",
    "Kitimat",
    "Ladysmith",
    "Lake Country",
    "Lake Cowichan",
    "Langford",
    "Langley City",
    "Langley Township",
    "Lantzville",
    "Lillooet",
    "Lions Bay",
    "Logan Lake",
    "Lumby",
    "Lytton",
    "Mackenzie",
    "Maple Ridge",
    "Masset",
    "McBride",
    "Merritt",
    "Metchosin",
    "Midway",
    "Mission",
    "Montrose",
    "Nakusp",
    "Nanaimo",
    "Nelson",
    "New Denver",
    "New Hazelton",
    "New Westminster",
    "North Cowichan",
    "North Saanich",
    "North Vancouver City",
    "North Vancouver District",
    "NRRM",
    "Oak Bay",
    "Oliver",
    "Osoyoos",
    "Parksville",
    "Peachland",
    "Pemberton",
    "Penticton",
    "Pitt Meadows",
    "Port Alberni",
    "Port Alice",
    "Port Clements",
    "Port Coquitlam",
    "Port Edward",
    "Port Hardy",
    "Port McNeill",
    "Port Moody",
    "Pouce Coupe",
    "Powell River",
    "Prince George",
    "Prince Rupert",
    "Princeton",
    "Qualicum Beach",
    "Quesnel",
    "Radium Hot Springs",
    "Revelstoke",
    "Richmond",
    "Rossland",
    "Saanich",
    "Salmo",
    "Salmon Arm",
    "Sayward",
    "Sechelt",
    "Sicamous",
    "Sidney",
    "Silverton",
    "Slocan",
    "Smithers",
    "Sooke",
    "Spallumcheen",
    "Sparwood",
    "Squamish",
    "Stewart",
    "Summerland",
    "Sun Peaks",
    "Surrey",
    "Tahsis",
    "Taylor",
    "Telkwa",
    "Terrace",
    "Tofino",
    "Trail",
    "Tumbler Ridge",
    "Ucluelet",
    "Valemont",
    "Vancouver",
    "Vanderhoof",
    "Vernon",
    "Victoria",
    "View Royal",
    "Warfield",
    "Wells",
    "West Kelowna",
    "West Vancouver",
    "Whistler",
    "White Rock",
    "Williams Lake",
    "Zeballos",
];
