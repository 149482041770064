export const formatDateTimeString = (datetime: string, includeTime: boolean = true): string => {
    if (!datetime) {
        return "—";
    }

    let date;
    if (datetime === "now") {
        date = new Date();
    } else {
        date = new Date(datetime);
        date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    }

    let formatterOptions: object = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };

    if (includeTime) {
        formatterOptions = {
            ...formatterOptions,
            hour: "2-digit",
            minute: "2-digit",
        };
    }

    const formatter = new Intl.DateTimeFormat("en-CA", formatterOptions);

    return formatter.format(date);
};

export const isStringValidDate = (dateString: string, allowEmpty = true): boolean => {
    if (allowEmpty && !dateString) {
        return true;
    }

    const date = new Date(dateString);
    return !isNaN(date.getTime());
};
