<template>
  <div
    :id="name"
    :class="`modal ${size ? `modal-${size}` : 'modal-lg'}`"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      :class="{
        'modal-dialog-centered': centered,
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="title">
            <h5 class="modal-title">
              Modals title
            </h5>
          </slot>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <slot name="body">
            <p>Modals body text goes here.</p>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
            >
              Save changes
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {type ModalName, useModalStore} from "~/store/modal";

const {$bootstrap} = useNuxtApp();

const modalStore = useModalStore();

const emit = defineEmits([
  "open",
  "close"
]);

const props = defineProps<{
  name: ModalName;
  size: "sm" | "md" | "lg" | "xl" | "xxl";
  centered?: boolean;
}>();

onMounted(() => {
  const modalElement = document.getElementById(props.name);
  modalElement?.addEventListener("hidden.bs.modal", () => modalStore.close(props.name));

  const modal = new $bootstrap.Modal(modalElement, {
    keyboard: false,
  });

  modalStore.$subscribe((mutation, state) => {
    const modalState = state.modals.find((modal) => modal.name === props.name);
    if (!modalState) {
      return;
    }

    if (modalState.open) {
      modal.show();
      emit("open");
    } else {
      modal.hide();
      emit("close");
    }
  }, {
    detached: true,
  });
});

onBeforeUnmount(() => {
  const modalElement = document.getElementById(props.name);
  modalElement?.removeEventListener("hidden.bs.modal", () => modalStore.close(props.name));
});
</script>
