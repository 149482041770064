<template>
  <time
    v-tooltip="{
      content: moment(addedAt).format('YYYY-MM-DD HH:mm:ss'),
      placement: 'left'
    }"
  >
    {{ formattedTime }}
  </time>
</template>

<script setup lang="ts">
import moment from "moment/moment";

const props = defineProps<{
  addedAt: string | Date;
}>();

const formattedTime = ref("");

onMounted(() => {
  formattedTime.value = moment().to(props.addedAt);

  const intervalId = setInterval(() => {
    formattedTime.value = moment().to(props.addedAt);
  }, 1000);

  onBeforeUnmount(() => {
    clearInterval(intervalId);
  });
});
</script>
