<template>
  <Modal
    name="ProjectLoad"
    size="md"
    :centered="true"
    @close="selectedProject = null"
  >
    <template #title>
      Project Load
    </template>

    <template #body>
      <ClientOnly>
        <div class="d-flex gap-2">
          <VueMultiselect
            v-model="selectedProject"
            :options="projectStore.projects"
            deselect-label="Selected"
            select-label=""
            placeholder="Select a project"
            :custom-label="project => project.name"
          />
          <IconButton
            :disabled="selectedProject === null"
            button-class="btn-danger"
            bootstrap-icon="trash3"
            value="Remove"
            @click="onDeleteProject"
          />
        </div>
      </ClientOnly>
    </template>

    <template #footer>
      <IconButton
        button-class="btn-dark"
        bootstrap-icon="dropbox"
        value="Load Project"
        @click="onLoadProject"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import VueMultiselect from "vue-multiselect";
import type {ProjectInterface} from "~/server/models/project";
import {useModalStore} from "~/store/modal";
import {useProjectStore} from "~/store/project";

const modalStore = useModalStore();
const projectStore = useProjectStore();

const selectedProject = ref<ProjectInterface | null>(null);

const onDeleteProject = () => {
  projectStore.delete(selectedProject.value);
  selectedProject.value = null;
};

const onLoadProject = () => {
  projectStore.load(selectedProject.value);
  selectedProject.value = null;
  modalStore.close("ProjectLoad");
};
</script>
