<template>
  <div
    :class="`${parentClass}`"
    @dblclick="onDoubleClick"
  >
    <input
      v-if="editing"
      ref="input"
      v-model="inputValue"
      :style="{
        width: `${inputValue?.length ?? 0}ch`,
      }"
      :class="`input ${inputClass}`"
      :type="options.inputType"
      :step="options.inputNumberStep ?? 1"
      :placeholder="options.inputPlaceholder ?? ''"
      @blur="saveChangedValue"
      @keydown.enter="saveChangedValue"
      @keydown.esc="revertValue"
    >

    <div
      v-else
      :class="`${textClass} ${getTextAlignClass()}`"
      :title="formattedValue"
    >
      {{ formattedValue }}
    </div>
  </div>
</template>

<script setup lang="ts">
import clone from "clone";
import type {RenderedTrade} from "~/models/rendered-trade";
import type {TradeInterface} from "~/server/models/trade";
import {useToastStore} from "~/store/toast";
import {useRenderedTradesStore} from "~/store/rendered-trades";
import type {TODO} from "~/types/todo";
import {getFormattedValue, getValueToSave, getValueValidation, tradeFieldsToOptions} from "~/utils/trade-fields";

const loadingIndicator = useLoadingIndicator();

const toastStore = useToastStore();
const renderedTradesStore = useRenderedTradesStore();

const emit = defineEmits([
  "error"
]);

const props = defineProps<{
  trade: RenderedTrade;
  field: keyof TradeInterface;
  parentClass?: string;
  inputClass?: string;
  textClass?: string;
  alignTextBasedOnType?: boolean;
}>();

const options = computed(() => tradeFieldsToOptions[props.field as keyof TradeInterface]);
const formattedValue = computed(() => getFormattedValue(props.field, props.trade));

const inputValue = ref(props.trade[props.field as keyof TradeInterface]);

const input = ref<HTMLInputElement | null>(null);
const editing = ref(false);
const error = ref(null);

const getTextAlignClass = () => {
  if (!props.alignTextBasedOnType) {
    return;
  }

  return options.value.inputType === "number" ? "text-end" : "";
};

const onDoubleClick = async () => {
  if (renderedTradesStore.lockTradeInputs) {
    return;
  }

  if (options.value.calculatedField) {
    return;
  }

  if (error.value) {
    return;
  }

  if (editing.value) {
    return;
  }

  editing.value = true;

  let value = props.trade[props.field as keyof TradeInterface];
  if (options.value.inputType === "date" && value) {
    value = value.split("T")[0]; // Mongo returns DateTime for Date fields, cut the time part
  }

  inputValue.value = value;

  await nextTick();
  input.value?.focus();
};

const revertValue = () => {
  editing.value = false;
  inputValue.value = props.trade[props.field as keyof RenderedTrade];
};

const saveChangedValue = async () => {
  loadingIndicator.start();

  const validation = getValueValidation(props.field, inputValue.value);
  const errorMessage = validation.message;

  if (!validation.valid) {
    toastStore.addToast(errorMessage, "danger");
    emit("error", errorMessage);
    error.value = errorMessage;

    input.value.focus();
    loadingIndicator.finish();
    return;
  }

  emit("error", null);
  error.value = null;
  editing.value = false;

  const clonedTrade = clone<RenderedTrade>(props.trade);
  clonedTrade[props.field as TODO] = getValueToSave(props.field, inputValue.value);
  renderedTradesStore.update([clonedTrade]);

  loadingIndicator.finish();
};
</script>

<style scoped lang="scss">
.input {
  min-width: 100%;
  position: absolute;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  z-index: 99999;
}
</style>
