<template>
  <div
    class="d-flex flex-row flex-nowrap"
    :class="{
      'input-group': trade,
      'w-100': !trade,
    }"
  >
    <TradeFieldSelect
      v-model="field"
      :class="{'select-field': trade}"
    />

    <div
      v-if="trade"
      class="form-control form-control-sm p-0"
      :class="{'is-invalid border-danger': error}"
    >
      <TradeDynamicInput
        parent-class="same-width"
        :input-class="`form-control form-control-sm dynamic-input ${error ? 'is-invalid text-danger' : ''}`"
        text-class="dynamic-text"
        :trade
        :field
        @error="onError"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type {TradeInterface} from "~/server/models/trade";

defineProps<{
  trade: TradeInterface | null;
}>();

const field = defineModel<keyof TradeInterface>();
const error = ref<string | null>(null);

const onError = (message: string) => {
  error.value = message;
};
</script>

<style lang="scss">
.input-group .multiselect__tags {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.same-width {
  flex: 1 1 0;
}

.select-field {
  width: 35%;
  max-width: 225px;
}

.dynamic-input, .dynamic-text {
  padding: 0 0.5rem;
  line-height: 32px;
}

.dynamic-input {
  border: 0;
  width: 100%;
}

.dynamic-text {
  overflow: hidden;
  white-space: nowrap;
  max-height: 32px;

  &:hover {
    overflow-x: auto;
  }
}

.whisper-trade-text {
  opacity: .4;
}
</style>
