<template>
  <Modal
    name="AdvancedFilters"
    size="lg"
  >
    <template #title>
      Advanced Filters
    </template>

    <template #body>
      <form>
        <div class="row">
          <div
            v-for="(options, field) in tradeFieldsToOptions"
            :key="field"
            :class="{
              'col-12': field === 'pids',
              'col-6': field !== 'pids',
            }"
          >
            <FilterInput
              :form
              :field
              :options
            />
          </div>
        </div>
      </form>
    </template>

    <template #footer>
      <IconButton
        button-class="btn-dark"
        bootstrap-icon="search"
        type="submit"
        value="Search"
        @click="submitClick"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import {useModalStore} from "~/store/modal";
import {useRenderedTradesStore} from "~/store/rendered-trades";
import {tradeFieldsToOptions} from "~/utils/trade-fields";
import {useTableStore} from "~/store/table";
import {useFilterFormStore} from "~/store/filter-form";

const modalStore = useModalStore();
const renderedTradesStore = useRenderedTradesStore();
const tableStore = useTableStore();
const filterFormStore = useFilterFormStore();

const submitClick = () => {
  tableStore.deselectAllTrades();
  renderedTradesStore.render(filterFormStore.getAllFilters);
  modalStore.close("AdvancedFilters");
};
</script>
