import type {InputType} from "~/utils/trade-fields";

export enum RangeType {
    LARGER_OR_EQUAL_THAN = "from",
    SMALLER_OR_EQUAL_THAN = "to",
    EQUAL_TO = "equal",
}

export const RangeTypeSymbols = {
    [RangeType.LARGER_OR_EQUAL_THAN]: "≥",
    [RangeType.SMALLER_OR_EQUAL_THAN]: "≤",
    [RangeType.EQUAL_TO]: "=",
} as const;

export function getRangeTypeFromSymbol(symbol: string): RangeType {
    const entry = Object.entries(RangeTypeSymbols).find(([_, value]) => value === symbol);
    if (!entry) {
        throw new Error(`Invalid range type symbol: ${symbol}`);
    }

    return entry[0] as RangeType;
}

export function getRangeTypeSymbolsForInputType(inputType: InputType): string[] {
  if (["date", "number", "single-number"].includes(inputType)) {
    return [
        RangeTypeSymbols[RangeType.LARGER_OR_EQUAL_THAN],
        RangeTypeSymbols[RangeType.SMALLER_OR_EQUAL_THAN],
        RangeTypeSymbols[RangeType.EQUAL_TO],
    ];
  }
  return [RangeTypeSymbols[RangeType.EQUAL_TO]];
}