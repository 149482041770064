export type ToastType = "success" | "danger" | "warning" | "info";

export interface Toast {
    message: string;
    type: ToastType;
    addedAt: Date;
    visibleInShade?: boolean;
}

export const useToastStore = defineStore("toast", {
    state: () => ({
        toasts: [] as Toast[],
        panelOpened: false,
    }),
    actions: {
        async populate() {
            const response = await useFetch("/api/toasts");

            const toasts = response.data.value?.data as unknown as Toast[] || [];
            this.toasts = toasts;
        },
        addToast(message: string, type: ToastType) {
            const toast = {
                message: message,
                type: type,
                addedAt: new Date(),
                visibleInShade: true,
            };

            this.toasts.unshift(toast);

            setTimeout(() => {
                const index = this.toasts.indexOf(toast);
                if (index !== -1) {
                    this.toasts[index].visibleInShade = false;
                }
            }, 5000);

            useFetch("/api/toasts", {
                method: "POST",
                body: {
                    message: message,
                    type: type,
                    addedAt: new Date(),
                },
            });
        },
        hideToast(index: number) {
            this.toasts[index].visibleInShade = false;
        },
        togglePanel() {
            this.panelOpened = !this.panelOpened;
        },
    },
});
