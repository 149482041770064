<template>
  <VueMultiselect
    v-model="field"
    :options="selectOptions"
    :allow-empty="false"
    deselect-label="Selected"
    select-label=""
    :custom-label="field => tradeFieldsToOptions[field].readableName"
  />
</template>

<script setup lang="ts">
import VueMultiselect from "vue-multiselect";
import {tradeFieldsToOptions} from "~/utils/trade-fields";

const field = defineModel<string>();
const selectOptions = Object.keys(tradeFieldsToOptions);
</script>
