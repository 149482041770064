import clone from "clone";
import type {LatLngTuple} from "leaflet";
import type {RenderedTrade} from "~/models/rendered-trade";
import {useMapPairsStore} from "~/store/map-pairs";
import {useRenderedTradesStore} from "~/store/rendered-trades";
import {useTableStore} from "~/store/table";

declare const L: typeof import("leaflet");

export interface TileLayer {
    name: string;
    url: string;
    visible: boolean;
}

export enum SpecialMapMode {
    Normal = "normal",
    SelectTradeOnClick = "select-trade-on-click",
}

export const defaultCenter = [49.24, -123.05] as LatLngTuple;
export const defaultZoom = 13;

export const useMapStore = defineStore("map", {
    state: () => ({
        map: null as L.Map | null,
        center: clone(defaultCenter) as LatLngTuple,
        zoom: clone(defaultZoom),
        maxZoom: 18,
        tileLayers: [
            {
                name: "OpenStreetMap",
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                visible: false,
            },
            {
                name: "Google Satellite",
                url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
                visible: true,
            },
        ] as TileLayer[],
        mapMode: SpecialMapMode.Normal,
    }),
    getters: {
        currentTileLayer: (state) => state.tileLayers.find((tl) => tl.visible) as TileLayer,
    },
    actions: {
        setVisibleTileLayer(name: string) {
            this.tileLayers.forEach((tileLayer) => {
                if (tileLayer.name === name) {
                    tileLayer.visible = true;
                    return;
                }

                tileLayer.visible = false;
            });
        },
        flyToDefault() {
            this.map?.flyTo(defaultCenter, defaultZoom);
        },
        async flyToTrade(trade: RenderedTrade) {
            const mapPairsStore = useMapPairsStore();

            const polygon = mapPairsStore.getPolygon(trade._id);
            const polygonBounds = polygon?.getBounds();

            if (!polygonBounds) {
                return;
            }

            if (!polygonBounds.isValid()) {
                return;
            }

            this.map.flyToBounds(polygonBounds, {
                padding: L.point(36, 36),
                animate: false,
            });

            await nextTick();
            polygon?.fire("click");
        },
        async flyToVisibleTrades() {
            const renderedTradesStore = useRenderedTradesStore();
            const mapPairsStore = useMapPairsStore();

            const bounds = new L.LatLngBounds([]);
            const visibleTrades = renderedTradesStore.renderedTrades.filter((trade) => !trade.state.hidden);

            await nextTick();
            for (const trade of visibleTrades) {
                await nextTick();
                const polygon = mapPairsStore.getPolygon(trade._id);
                const polygonBounds = polygon?.getBounds();
                if (!polygonBounds) {
                    continue;
                }

                bounds.extend(polygonBounds);
            }

            if (bounds.isValid()) {
                this.map.flyToBounds(bounds, {
                    padding: [45, 45],
                    animate: false,
                });
            }
        },
        isTradeInViewport(trade: RenderedTrade) {
            const mapStore = useMapStore();
            const mapPairsStore = useMapPairsStore();

            const polygon = mapPairsStore.getPolygon(trade._id);
            if (!polygon) {
                return false;
            }

            if (Object.keys(polygon.getBounds()).length === 0) {
                return false;
            }

            const polygonCenter = polygon.getBounds().getCenter();

            return mapStore.map.getBounds().contains(polygonCenter);
        },
        async resetMapMode() {
            const renderedTradesStore = useRenderedTradesStore();
            const tableStore = useTableStore();

            this.mapMode = SpecialMapMode.Normal;
            await renderedTradesStore.refreshOpenBubbles();
            tableStore.deselectAllTrades();
        }
    },
});
