import {useRenderedTradesStore} from "~/store/rendered-trades";

export const useMapPairsStore = defineStore("map-pairs", {
    state: () => ({
        popups: {} as Record<string, L.Popup>,
        markers: {} as Record<string, L.Marker>,
        polygons: {} as Record<string, L.Polygon>,
        polylines: {} as Record<string, L.Polyline>,
        draggables: {} as Record<string, L.Draggable>,
        assemblyPolygons: {} as Record<string, L.Polygon>[],
        fixTimeouts: {} as Record<string, ReturnType<typeof setTimeout>>,
    }),
    getters: {
        getPopup: (state) => (tradeId: string) => state?.popups[tradeId] || null,
        getMarker: (state) => (tradeId: string) => state?.markers[tradeId] || null,
        getPolygon: (state) => (tradeId: string) => state?.polygons[tradeId] || null,
        getPolyline: (state) => (tradeId: string) => state?.polylines[tradeId] || null,
        getDraggable: (state) => (tradeId: string) => state?.draggables[tradeId] || null,
        getAssemblyPolygons: (state) => (tradeId: string) => state?.assemblyPolygons[tradeId] || [],
    },
    actions: {
        setPopupPair(tradeId: string, popup: L.Popup) {
            this.popups[tradeId] = popup;
        },
        setMarkerPair(tradeId: string, marker: L.Marker) {
            this.markers[tradeId] = marker;
        },
        setPolygonPair(tradeId: string, polygon: L.Polygon) {
            this.polygons[tradeId] = polygon;
        },
        setPolylinePair(tradeId: string, polyline: L.Polyline) {
            this.polylines[tradeId] = polyline;
        },
        setDraggablePair(tradeId: string, draggable: L.Draggable) {
            this.draggables[tradeId] = draggable;
        },
        addAssemblyPolygonPair(tradeId: string, assemblyPolygon: L.Polygon) {
            this.assemblyPolygons[tradeId] = [...(this.assemblyPolygons[tradeId] || []), assemblyPolygon];
        },
        async fixBubblePositions() {
            const renderedTradesStore = useRenderedTradesStore();

            await nextTick();

            for (const [tradeId, draggable] of Object.entries(this.draggables)) {
                // This fixes invisible polylines when map is moved/zoomed
                // via listing on "reposition" event in Map.vue
                draggable.fire("reposition");

                const polyline = this.getPolyline(tradeId);
                if (!polyline) {
                    continue;
                }

                const renderedTrade = renderedTradesStore.getTradeById(tradeId);
                if (!renderedTrade?.state.bubbleOpen) {
                    polyline.setLatLngs([]);
                }
            }
        }
    },
});
