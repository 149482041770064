import type {TODO} from "~/types/todo";
import type {TradeInterface} from "~~/server/models/trade";

export const WHISPER_STATUS = "Whisper";

export type RenderedTradeSettings = {
    titleField: keyof TradeInterface,
    bodyFields: (keyof TradeInterface | null)[],
    imageUrl: string | null,
    outlineWeight: number,
    outlineColor: string,
    fillColor: string,
    fillOpacity: number,
    separatorWeight: number,
    separatorColor: string,
    bubbleTextColor: string,
    bubbleBackgroundColor: string,
    bubbleZoom: number,
};

export type RenderedTradeState = {
    bubbleOpen: boolean,
    bubblePosition: [number, number] | null,
    polyLinePosition: [number, number] | null,
    hidden: boolean,
};

export const defaultSettings: RenderedTradeSettings = {
    titleField: "purchaser",
    bodyFields: ["address", "price", "psfb", "grossSqft", "startDate", "endDate"],
    imageUrl: null,
    outlineWeight: 3.5,
    outlineColor: "#FA2200",
    fillColor: "#3389FF",
    fillOpacity: 0.2,
    separatorWeight: 1,
    separatorColor: "#3389FF",
    bubbleTextColor: "#000000",
    bubbleBackgroundColor: "#FFFFFF",
    bubbleZoom: 1.0,
};

export const defaultState: RenderedTradeState = {
    bubbleOpen: false,
    bubblePosition: null,
    hidden: true,
};

// TODO: This should not be class, but a dictionary object to prevent confusion when recreating, or cloning the object
export class RenderedTrade implements TradeInterface {
    constructor(
        public _id: string,
        public purchaser: string,
        public pids: string[],
        public status: string,
        public startDate: Date,
        public endDate: Date,
        public address: string,
        public municipality: string,
        public ncp: string,
        public price: number,
        public grossAcres: number,
        public netAcres: number,
        public propertyType: string,
        public landUseDesignation: string,
        public gfaModifier: number,
        public densityType: string,
        public notes: string,
        public createdBy: string,
        public insertedAt: Date,
        public grossSqft: number,
        public netSqft: number,
        public priceAcreGross: number,
        public priceAcreNet: number,
        public grossPsfl: number,
        public netPsfl: number,
        public units: number,
        public fsr: number,
        public psfb: number,
        public pricePerDoor: number,
        public gfa: number,
        public upa: number,
        public whisper: boolean,
        public geoData: TODO,
        public deleted: boolean,
        public settings?: RenderedTradeSettings,
        public state?: RenderedTradeState,
    ) {
    }

    // TODO: Replace with getNotEmptyGeoData from utils/geojson.ts as we do not always have access to class object, but dictionary object
    public getNotEmptyGeoData() {
        return this.geoData.filter((geojson: TODO) => {
            return geojson?.geometry?.coordinates;
        });
    }

    public update(renderedTrade: RenderedTrade) {
        this._id = renderedTrade._id;
        this.purchaser = renderedTrade.purchaser;
        this.pids = renderedTrade.pids;
        this.status = renderedTrade.status;
        this.startDate = renderedTrade.startDate;
        this.endDate = renderedTrade.endDate;
        this.address = renderedTrade.address;
        this.municipality = renderedTrade.municipality;
        this.ncp = renderedTrade.ncp;
        this.price = renderedTrade.price;
        this.grossAcres = renderedTrade.grossAcres;
        this.netAcres = renderedTrade.netAcres;
        this.propertyType = renderedTrade.propertyType;
        this.landUseDesignation = renderedTrade.landUseDesignation;
        this.gfaModifier = renderedTrade.gfaModifier;
        this.densityType = renderedTrade.densityType;
        this.notes = renderedTrade.notes;
        this.createdBy = renderedTrade.createdBy;
        this.insertedAt = renderedTrade.insertedAt;
        this.grossSqft = renderedTrade.grossSqft;
        this.netSqft = renderedTrade.netSqft;
        this.priceAcreGross = renderedTrade.priceAcreGross;
        this.priceAcreNet = renderedTrade.priceAcreNet;
        this.grossPsfl = renderedTrade.grossPsfl;
        this.netPsfl = renderedTrade.netPsfl;
        this.units = renderedTrade.units;
        this.fsr = renderedTrade.fsr;
        this.psfb = renderedTrade.psfb;
        this.pricePerDoor = renderedTrade.pricePerDoor;
        this.gfa = renderedTrade.gfa;
        this.upa = renderedTrade.upa;
        this.geoData = renderedTrade.geoData;
        this.settings = renderedTrade.settings;
    }
}
