export const MIN_ZOOM = 0.5;
export const MAX_ZOOM = 2.5;

export const getLimitedZoom = (zoom: number) => {
    zoom = Number(Number(zoom).toFixed(1));

    if (zoom < MIN_ZOOM) {
        return MIN_ZOOM;
    }

    if (zoom > MAX_ZOOM) {
        return MAX_ZOOM;
    }

    return zoom;
};
