<template>
  <div
    class="toast show align-items-center border-0"
    :class="`text-bg-${type}`"
  >
    <div class="d-flex">
      <div class="toast-body w-100">
        {{ message }}

        <div
          v-if="showTimeAgo"
          class="d-flex mt-2 text-muted justify-content-end"
        >
          <small>
            <LiveTimeAgo :added-at="addedAt" />
          </small>
        </div>
      </div>

      <button
        v-if="hideable"
        type="button"
        class="btn-close me-2 m-auto"
        @click="toastStore.hideToast(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from "moment/moment";
import {useToastStore} from "~/store/toast";

const toastStore = useToastStore();

defineProps<{
  message: string;
  type: string;
  addedAt: string;
  index: number;
  hideable: boolean;
  showTimeAgo: boolean;
}>();
</script>
