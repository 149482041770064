import { default as _91id_93nZqpBa0BYvMeta } from "/codebuild/output/src4073906059/src/mosaic-land/pages/import/[id].vue?macro=true";
import { default as indexfk0zIM9BkCMeta } from "/codebuild/output/src4073906059/src/mosaic-land/pages/import/index.vue?macro=true";
import { default as indexU1AZrQbNxhMeta } from "/codebuild/output/src4073906059/src/mosaic-land/pages/index.vue?macro=true";
import { default as login4NgsFXza48Meta } from "/codebuild/output/src4073906059/src/mosaic-land/pages/login.vue?macro=true";
import { default as newdNyUCMfxLuMeta } from "/codebuild/output/src4073906059/src/mosaic-land/pages/trade/new.vue?macro=true";
export default [
  {
    name: _91id_93nZqpBa0BYvMeta?.name ?? "import-id",
    path: _91id_93nZqpBa0BYvMeta?.path ?? "/import/:id()",
    meta: _91id_93nZqpBa0BYvMeta || {},
    alias: _91id_93nZqpBa0BYvMeta?.alias || [],
    redirect: _91id_93nZqpBa0BYvMeta?.redirect,
    component: () => import("/codebuild/output/src4073906059/src/mosaic-land/pages/import/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfk0zIM9BkCMeta?.name ?? "import",
    path: indexfk0zIM9BkCMeta?.path ?? "/import",
    meta: indexfk0zIM9BkCMeta || {},
    alias: indexfk0zIM9BkCMeta?.alias || [],
    redirect: indexfk0zIM9BkCMeta?.redirect,
    component: () => import("/codebuild/output/src4073906059/src/mosaic-land/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexU1AZrQbNxhMeta?.name ?? "index",
    path: indexU1AZrQbNxhMeta?.path ?? "/",
    meta: indexU1AZrQbNxhMeta || {},
    alias: indexU1AZrQbNxhMeta?.alias || [],
    redirect: indexU1AZrQbNxhMeta?.redirect,
    component: () => import("/codebuild/output/src4073906059/src/mosaic-land/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4NgsFXza48Meta?.name ?? "login",
    path: login4NgsFXza48Meta?.path ?? "/login",
    meta: login4NgsFXza48Meta || {},
    alias: login4NgsFXza48Meta?.alias || [],
    redirect: login4NgsFXza48Meta?.redirect,
    component: () => import("/codebuild/output/src4073906059/src/mosaic-land/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newdNyUCMfxLuMeta?.name ?? "trade-new",
    path: newdNyUCMfxLuMeta?.path ?? "/trade/new",
    meta: newdNyUCMfxLuMeta || {},
    alias: newdNyUCMfxLuMeta?.alias || [],
    redirect: newdNyUCMfxLuMeta?.redirect,
    component: () => import("/codebuild/output/src4073906059/src/mosaic-land/pages/trade/new.vue").then(m => m.default || m)
  }
]