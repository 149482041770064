<template>
  <div class="toast-container">
    <template
      v-for="(toast, index) in toastStore.toasts"
      :key="index"
    >
      <Toast
        v-if="toast.visibleInShade"
        :message="toast.message"
        :added-at="toast.addedAt"
        :type="toast.type"
        :index="index"
        :hideable="true"
        :show-time-ago="false"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {useToastStore} from "~/store/toast";

const toastStore = useToastStore();
</script>

<style scoped lang="scss">
.toast-container {
  position: fixed;
  top: 35px;
  right: 5px;
  z-index: 9999;
}
</style>
