<template>
  <label>{{ label }}</label>
  <div class="d-flex gap-2">
    <div class="input-group d-flex">
      <input
        ref="imageInput"
        type="file"
        accept="image/*"
        class="form-control form-control-sm"
        @change="onChange"
      >
      <img
        v-if="model"
        :src="model"
        class="trade-image-preview"
        alt="Trade Image Preview"
      >
    </div>

    <IconButton
      v-if="model"
      button-class="btn-danger"
      bootstrap-icon="trash3"
      @click="model = null"
    />
  </div>
</template>

<script setup lang="ts">
import "vue-multiselect/dist/vue-multiselect.css";
import {useModalStore} from "~/store/modal";

defineProps<{
  label?: string;
}>();

const emit = defineEmits(["change", "finish"]);

const loadingIndicator = useLoadingIndicator();
const modalStore = useModalStore();

const model = defineModel<string>();
const imageInput = ref<HTMLInputElement | null>(null);

watch(
  () => modalStore.modals,
  () => imageInput.value.value = "",
  { deep: true },
);

const onChange = async () => {
  loadingIndicator.start();
  emit("change");

  const files = imageInput.value?.files;
  const file = files && files.length > 0 ? files[0] : null;

  if (!file) {
    return;
  }

  const formData = new FormData();
  formData.append("file", file);

  const response = await useFetch<{ data: { id: string } }>("/api/files/upload", {
    method: "POST",
    body: formData,
  });

  const fileId = response.data.value?.data.id;
  const imageUrl = fileId ? `/files/${fileId}` : null;
  model.value = imageUrl;

  emit("finish");
  loadingIndicator.finish();
};
</script>

<style scoped lang="scss">
.trade-image-preview {
  max-height: 35px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>
