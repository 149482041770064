import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
    if (!process.client) {
        return;
    }

    const { replayIntegration } = await import("@sentry/vue");
    Sentry.addIntegration(replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
    }));
}

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();
    const browserTracing = new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    });

    return [browserTracing];
}

export default defineNuxtPlugin({
    name: "sentry",
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;
        const config = useRuntimeConfig();

        Sentry.init({
            app: vueApp,
            dsn: config.public.SENTRY_DSN_PUBLIC as string ?? null,
            integrations: getSentryIntegrations(),
            tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
            tracePropagationTargets: ["localhost"], // ["localhost", /^https:\/\/yourserver\.io\/api/]
            replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,
            replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
        });

        lazyLoadSentryIntegrations();
    },
});
