<template>
  <Modal
    name="GroupsCustomization"
    size="xxl"
    @open="onModalOpen"
    @close="mapPairsStore.fixBubblePositions"
  >
    <template #title>
      Groups Customizations
    </template>

    <template #body>
      <table class="table table-sm table-hover table-striped table-bordered m-0">
        <thead>
          <tr class="border-white">
            <td
              rowspan="2"
              class="pids-column align-middle p-3"
            >
              <div>
                <VueMultiselect
                  v-model="groupByField"
                  :options="Object.keys(tradeFieldsToOptions)"
                  deselect-label="Selected"
                  select-label=""
                  :custom-label="field => tradeFieldsToOptions[field].readableName"
                  @select="populateForm"
                />
              </div>
            </td>

            <td
              v-for="([value, count], index) in getCommonValuesToCount()"
              :key="index"
            >
              <div class="d-flex align-items-center justify-content-center flex-column gap-2">
                <input
                  v-model="colors[index]"
                  type="color"
                  class="form-control form-control-color"
                >

                <div
                  v-tooltip="{
                    content: `<b>${count}&times;</b><br />${value}`,
                    html: true,
                  }"
                  class="value-ellipsis text-center"
                >
                  {{ value }}
                </div>
              </div>
            </td>

            <td>
              <div class="d-flex align-items-center justify-content-center flex-column gap-2">
                <div
                  v-tooltip="'Keeps current colors'"
                  class="form-control form-control-color d-flex align-items-center justify-content-center"
                >
                  <i class="bi bi-ban" />
                </div>
                Other Values
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            :key="row.trade._id"
          >
            <td>
              <div class="value-ellipsis">
                {{ getFormattedValue("purchaser", row.trade) }}
              </div>
            </td>
            <td
              v-for="([], index) in getCommonValuesToCount()"
              :key="index"
            >
              <label class="text-center w-100">
                <input
                  type="radio"
                  class="form-check-input"
                  :name="row.trade._id"
                  :checked="row.index === index"
                  @change="row.color = colors[index]; row.index = index"
                >
              </label>
            </td>
            <td>
              <label class="text-center w-100">
                <input
                  type="radio"
                  class="form-check-input"
                  :name="row.trade._id"
                  :checked="row.color === null"
                  @change="row.color = null; row.index = null"
                >
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template #footer>
      <IconButton
        button-class="btn-dark"
        bootstrap-icon="floppy-fill"
        value="Apply Changes"
        @click="onApplyChangesClick"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import clone from "clone";
import VueMultiselect from "vue-multiselect";
import type {RenderedTrade} from "~/models/rendered-trade";
import type {TradeInterface} from "~/server/models/trade";
import {useMapPairsStore} from "~/store/map-pairs";
import {useModalStore} from "~/store/modal";
import {useRenderedTradesStore} from "~/store/rendered-trades";
import {adjustHexColor} from "~/utils/color";
import {getFormattedValue, tradeFieldsToOptions} from "~/utils/trade-fields";

const maxCommonValuesToShow = 5;
const colors = ref({
  0: "#f51919",
  1: "#30bff9",
  2: "#30fc30",
  3: "#f7f63c",
  4: "#d05cff",
});

const rows = ref<Array<{
  trade: RenderedTrade,
  color: string | null,
  index: number | null,
}>>([]);

const modalStore = useModalStore();
const mapPairsStore = useMapPairsStore();
const renderedTradesStore = useRenderedTradesStore();

const groupByField = ref<keyof TradeInterface>();
const trades = ref<RenderedTrade[]>([]);

const getCommonValuesToCount = () => {
  trades.value = renderedTradesStore.renderedTrades;

  const valuesToCount: Record<string, number> = {};
  for (const trade of trades.value) {
    const value = trade[groupByField.value];
    if (!value) {
      continue;
    }

    const formattedValue = getFormattedValue(groupByField.value, trade);

    valuesToCount[formattedValue] = (valuesToCount[value] ?? 0) + 1;
  }

  const sortedValuesToCount = Object.entries(valuesToCount)
    .sort(([, countA], [, countB]) => countB - countA);

  if (sortedValuesToCount.length > maxCommonValuesToShow) {
    sortedValuesToCount.splice(maxCommonValuesToShow, sortedValuesToCount.length - maxCommonValuesToShow);
  }

  return sortedValuesToCount;
};

const populateForm = async () => {
  await nextTick();
  rows.value = [];

  for (const trade of trades.value) {
    for (const [index, [value]] of Array.from(getCommonValuesToCount()).entries()) {
      const formattedValue = getFormattedValue(groupByField.value, trade);
      const clonedTrade = clone(trade);

      if (formattedValue === value) {
        rows.value.push({
          trade: clonedTrade,
          index: index,
          color: colors.value[index],
        });
        break;
      }

      if (index >= maxCommonValuesToShow - 1) {
        rows.value.push({
          trade: clonedTrade,
          index: null,
          color: null,
        });
      }
    }
  }
};

const onModalOpen = () => {
  groupByField.value = "municipality";
  populateForm();
};

const onApplyChangesClick = () => {
  for (const row of rows.value) {
    if (row.index === null) {
      continue;
    }

    const color = colors.value[row.index];
    const trade = renderedTradesStore.getTradeById(row.trade._id);

    trade.settings.fillColor = adjustHexColor(color, 10);
    trade.settings.outlineColor = color;
    trade.settings.separatorColor = color;
  }

  modalStore.close("GroupsCustomization");
};
</script>

<style scoped lang="scss">
table {
  table-layout: fixed;
}

thead > tr td {
  border-bottom: 1px solid #d9dee2;
}

thead tr td {
  vertical-align: middle;
}

table tr td:first-child {
  width: 45%;
}

.value-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
