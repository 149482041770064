<template>
  <label class="d-flex justify-content-between">
    <span>{{ label }}</span>
    <span>{{ Math.round(model as number * 100) }}%</span>
  </label>

  <input
    v-model="model"
    type="range"
    class="form-range"
    :min="MIN_ZOOM"
    :max="MAX_ZOOM"
    step="0.1"
  >
</template>

<script setup lang="ts">
import "vue-multiselect/dist/vue-multiselect.css";
import {MAX_ZOOM, MIN_ZOOM} from "~/utils/zoom";

defineProps<{
  label?: string;
}>();

const model = defineModel<string | number>({
  set(value: string) {
    return parseFloat(value);
  },
});
</script>
