export interface Modal {
    name: string;
    open: boolean;
    args: ModalArguments | null;
}

export interface ModalArguments {
    [key: string]: unknown;
}

export type ModalName = "AdvancedFilters" | "TradeCustomization" | "ProjectSave" | "ProjectLoad" | "GroupsCustomization";

// TODO: Reactivity is not working when modal is opened -> try to v-if it in ModalWrapper to see if it helps
export const useModalStore = defineStore("modal", {
    state: () => ({
        modals: [] as Modal[],
    }),
    actions: {
        open(modalName: ModalName, args: ModalArguments | null = null) {
            const modal = this.modals.find(modal => modal.name === modalName);

            if (modal) {
                modal.open = true;
                modal.args = args;
            } else {
                this.modals.push({
                    name: modalName,
                    open: true,
                    args: args,
                });
            }
        },
        close(modalName: ModalName) {
            const modal = this.modals.find(modal => modal.name === modalName);

            if (modal) {
                modal.open = false;
                modal.args = null;
            } else {
                this.modals.push({
                    name: modalName,
                    open: false,
                    args: null,
                });
            }
        }
    },
    getters: {
        arguments: (state) => (modalName: ModalName): ModalArguments | null => {
            const modal = state.modals.find(modal => modal.name === modalName);

            if (modal) {
                return modal.args;
            }

            return null;
        }
    }
});
