<template>
  <NuxtLoadingIndicator
    color="#0066f6"
    :throttle="100"
  />

  <ToastShade />
  <ToastPanel />

  <ModalsWrapper />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import {useAuthUser} from "~/composables/useAuthUser";
import {useProfileStore} from "~/store/profile";
import {useProjectStore} from "~/store/project";
import {useToastStore} from "~/store/toast";

const authUser = useAuthUser();
const toastStore = useToastStore();
const profileStore = useProfileStore();
const projectStore = useProjectStore();

watch(authUser, (user) => {
  if (!user) {
    return;
  }

  toastStore.populate();
  profileStore.populate();
  projectStore.populate();
}, {
  immediate: true,
});

onMounted(() => {
  useHead({
    title: "Mosaic LTS",
    bodyAttrs: {
      "data-operating-system": getOperatingSystem(),
    },
  });
});

const getOperatingSystem = (): "windows" | "macos" | "linux" => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("windows")) {
    return "windows";
  }

  if (userAgent.includes("mac os")) {
    return "macos";
  }

  if (userAgent.includes("linux")) {
    return "linux";
  }

  return "windows";
};
</script>
