<template>
  <label class="w-100">
    {{ label }}
    <input
      v-model="model"
      type="color"
      class="form-control form-control-color w-100"
    >
  </label>
</template>

<script setup lang="ts">
defineProps<{
  label?: string;
}>();

const model = defineModel<string>();
</script>
