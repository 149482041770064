import clone from "clone";
import * as FileSaver from "file-saver";
import {WHISPER_STATUS} from "~/models/rendered-trade";
import type {RenderedTrade} from "~/models/rendered-trade";
import type {TradeInterface} from "~/server/models/trade";
import {useRenderedTradesStore} from "~/store/rendered-trades";
import {getFormattedValue, tradeFieldsToOptions, type TradeOptions} from "~/utils/trade-fields";

export interface Column {
    field: keyof TradeInterface;
    options: TradeOptions;
    visible: boolean;
    order: "asc" | "desc" | null;
}

const columns: Column[] = Object.keys(tradeFieldsToOptions).map((field) => ({
    field: field as keyof TradeInterface,
    options: tradeFieldsToOptions[field as keyof TradeInterface],
    visible: true,
    order: null,
}));

export const useTableStore = defineStore("table", {
    state: () => ({
        columns: clone(columns),
        pageSize: 15,
        currentPage: 1,
        selectedTrades: [] as RenderedTrade[],
    }),
    actions: {
        toggleVisibility(field: keyof TradeInterface) {
            const column = this.columns.find((column) => column.field === field);
            if (!column) {
                return;
            }

            column.visible = !column.visible;
        },
        isColumnVisible(field: keyof TradeInterface) {
            const column = this.columns.find((column) => column.field === field);
            if (!column) {
                return false;
            }

            return column.visible;
        },
        isTradeSelected(trade: RenderedTrade) {
            return this.selectedTrades.some((selectedTrade) => selectedTrade._id === trade._id);
        },
        toggleOrder(field: keyof TradeInterface) {
            this.columns.forEach((column) => {
                if (column.field !== field) {
                    column.order = null;
                }
            });

            const column = this.columns.find((column) => column.field === field);
            if (!column) {
                return;
            }

            if (column.order === null) {
                column.order = "desc";
            } else if (column.order === "desc") {
                column.order = "asc";
            } else {
                column.order = null;
            }
        },
        moveColumn(field: keyof TradeInterface, direction: "up" | "down") {
            const index = this.columns.findIndex((column) => column.field === field);
            if (index === -1) {
                return;
            }

            if (direction === "up") {
                if (index === 0) {
                    return;
                }

                const column = this.columns[index];
                this.columns.splice(index, 1);
                this.columns.splice(index - 1, 0, column);
            } else {
                if (index === this.columns.length - 1) {
                    return;
                }

                const column = this.columns[index];
                this.columns.splice(index, 1);
                this.columns.splice(index + 1, 0, column);
            }
        },
        setPage(page: number) {
            this.currentPage = page;
        },
        setPageSize(pageSize: number) {
            this.pageSize = pageSize;
        },
        selectTrade(trade: RenderedTrade) {
            this.selectedTrades.push(trade);
        },
        unselectTrade(trade: RenderedTrade) {
            this.selectedTrades = this.selectedTrades.filter((selectedTrade) => selectedTrade._id !== trade._id);
        },
        selectAllTrades() {
            const renderedTradesStore = useRenderedTradesStore();
            this.selectedTrades = renderedTradesStore.renderedTrades;
        },
        deselectAllTrades() {
            this.selectedTrades = [];
        },
        reset() {
            this.columns = clone(columns);
            this.pageSize = 15;
            this.currentPage = 1;
            this.selectedTrades = [];
        },
        exportToCsv() {
            const columns = this.columns.filter((column) => column.visible);
            const headers = columns.map((field) => tradeFieldsToOptions[field.field].readableName);

            const rows = this.selectedTrades.map((trade) => {
                const row = [];
                for (const column of columns) {
                    const formattedValue = getFormattedValue(column.field, trade);
                    row.push(`"${formattedValue}"`);
                }
                return row;
            });

            const csvList = [headers.join(",")];
            for (const row of rows) {
                csvList.push(row.join(","));
            }

            const csv = csvList.join("\n");
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            const filename = `trades-${new Date().toISOString()}.csv`;

            FileSaver.saveAs(blob, filename);
        },
    },
    getters: {
        tradesCount() {
            const renderedTradesStore = useRenderedTradesStore();
            return renderedTradesStore.renderedTrades.length;
        },
        whisperTradesCount() {
            const renderedTradesStore = useRenderedTradesStore();
            // TODO: For some reason, the field `whisper` is not updated here so we are using status instead. This is a hack and we should fix it. Same in the Table.vue!
            return renderedTradesStore.renderedTrades.filter((trade) => trade.status === WHISPER_STATUS).length;
        },
        trades(state) {
            const renderedTradesStore = useRenderedTradesStore();
            const renderedTrades = clone(renderedTradesStore.renderedTrades);

            const columns = state.columns.filter((column) => column.order !== null);
            if (columns.length) {
                const column = columns[0];
                const order = column.order === "asc" ? 1 : -1;
                renderedTrades.sort((a, b) => {
                    if (!a || !b) {
                        return 0;
                    }

                    if (a.status === WHISPER_STATUS && b.status !== WHISPER_STATUS) {
                        return -1;
                    }

                    if (a.status !== WHISPER_STATUS && b.status === WHISPER_STATUS) {
                        return 1;
                    }


                    if (a[column.field] > b[column.field]) {
                        return order;
                    } else if (a[column.field] < b[column.field]) {
                        return -order;
                    } else {
                        return 0;
                    }
                });
            }

            const start = (state.currentPage - 1) * state.pageSize;
            const end = start + state.pageSize;

            return renderedTrades.slice(start, end);
        },
    }
});
