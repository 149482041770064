import clone from "clone";
import {RenderedTrade} from "~/models/rendered-trade";
import type {ProjectInterface} from "~/server/models/project";
import {ProjectTradeInterface} from "~/server/models/project-trade";
import type {TradeInterface} from "~/server/models/trade";
import {useMapStore} from "~/store/map";
import {useMapPairsStore} from "~/store/map-pairs";
import {useRenderedTradesStore} from "~/store/rendered-trades";

export const useProjectStore = defineStore("project", {
        state: () => ({
            currentProject: null as ProjectInterface | null,
            projects: [] as ProjectInterface[],
        }),
        actions: {
            async populate() {
                this.projects = [];
                const response = await useFetch("/api/projects");
                this.projects = response.data.value.data as unknown as ProjectInterface[];
            },
            async save(project: ProjectInterface) {
                const response = await useFetch("/api/projects", {
                    method: "POST",
                    body: clone(project),
                });

                const newProject = response.data.value.data as unknown as ProjectInterface;
                this.projects.push(newProject);
                this.currentProject = newProject;
            },
            async delete(project: ProjectInterface) {
                const loadingIndicator = useLoadingIndicator();
                loadingIndicator.start();

                await useFetch(`/api/projects/${project._id}`, {
                    method: "DELETE",
                });

                if (this.currentProject?._id === project._id) {
                    this.currentProject = null;
                }

                const index = this.projects.findIndex((p) => p._id === project._id);
                this.projects.splice(index, 1);

                loadingIndicator.finish();
            },
            async load(project: ProjectInterface) {
                const mapStore = useMapStore();
                const mapPairsStore = useMapPairsStore();

                const loadingIndicator = useLoadingIndicator();
                loadingIndicator.start();

                const renderedTradesStore = useRenderedTradesStore();
                await renderedTradesStore.clean(true);

                const renderedTrades = clone(project.projectTrades.map((projectTrade: ProjectTradeInterface) => {
                    const trade = projectTrade.trade as TradeInterface;
                    const settings = projectTrade.settings;
                    const state = projectTrade.state;

                    return new RenderedTrade(
                        trade._id,
                        trade.purchaser,
                        trade.pids,
                        trade.status,
                        trade.startDate,
                        trade.endDate,
                        trade.address,
                        trade.municipality,
                        trade.ncp,
                        trade.price,
                        trade.grossAcres,
                        trade.netAcres,
                        trade.propertyType,
                        trade.landUseDesignation,
                        trade.gfaModifier,
                        trade.densityType,
                        trade.notes,
                        trade.createdBy as string,
                        trade.insertedAt,
                        trade.grossSqft,
                        trade.netSqft,
                        trade.priceAcreGross,
                        trade.priceAcreNet,
                        trade.grossPsfl,
                        trade.netPsfl,
                        trade.units,
                        trade.fsr,
                        trade.psfb,
                        trade.pricePerDoor,
                        trade.gfa,
                        trade.upa,
                        trade.whisper,
                        trade.geoData,
                        false,
                        settings,
                        state,
                    );
                }));

                renderedTradesStore.renderedTrades = renderedTrades;
                this.currentProject = project;

                mapStore.setVisibleTileLayer(project.mapTileLayer);
                mapStore.map.flyTo(project.mapCenter, project.mapZoom, {
                    animate: false,
                });

                await nextTick();
                await renderedTradesStore.refreshOpenBubbles();

                loadingIndicator.finish();
            },
        },
    })
;
