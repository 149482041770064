import type {Directive} from "vue";

const clickOutside: Directive = {
    beforeMount(el, binding) {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
};

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.directive("click-outside", clickOutside);
});
