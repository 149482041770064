<template>
  <Modal
    name="TradeCustomization"
    size="lg"
    @open="onModalOpen"
    @close="mapPairsStore.fixBubblePositions"
  >
    <template #title>
      Trade Customization
    </template>

    <template #body>
      <div v-if="form">
        <label>Profile</label>
        <TradeProfileSelect
          :settings="form"
        />

        <div class="my-3 border-top" />

        <div class="d-flex gap-2">
          <TradeColorInput
            v-model="form.fillColor"
            label="Fill Color"
          />

          <TradeColorInput
            v-model="form.outlineColor"
            label="Border Color"
          />

          <TradeColorInput
            v-model="form.bubbleTextColor"
            label="Text Color"
          />

          <TradeColorInput
            v-model="form.bubbleBackgroundColor"
            label="Bubble Color"
          />
        </div>

        <div class="mt-2">
          <TradeImageInput
            v-model="form.imageUrl"
            label="Image"
            @change="submitDisabled = true"
            @finish="submitDisabled = false"
          />
        </div>

        <div class="mt-2">
          <TradeZoomInput
            v-model="form.bubbleZoom"
            label="Zoom Percentage"
          />
        </div>

        <div class="my-3 border-top" />

        <div>
          <label>Title</label>
          <TradeFieldSelectValuePair
            v-model="form.titleField"
            :trade="trade"
          />
        </div>

        <div class="mt-2">
          <label>Body</label>
          <div
            v-for="(bodyField, index) in form.bodyFields"
            :key="bodyField"
            :data-test-value="bodyField"
            data-test-id="body-row"
            class="d-flex justify-content-between align-items-center flex-shrink-0 gap-2"
            :class="index > 0 ? 'mt-2' : ''"
          >
            <TradeFieldSelectValuePair
              v-model="form.bodyFields[index]"
              :trade="trade"
            />

            <IconButton
              button-class="btn-outline-dark"
              bootstrap-icon="arrow-up"
              @click="moveBodyFieldUp(index)"
            />

            <IconButton
              button-class="btn-outline-dark"
              bootstrap-icon="arrow-down"
              @click="moveBodyFieldDown(index)"
            />

            <IconButton
              button-class="btn-danger"
              bootstrap-icon="trash3"
              @click="removeBodyField(index)"
            />
          </div>
        </div>

        <IconButton
          button-class="btn-success mt-2"
          bootstrap-icon="plus-square-fill"
          data-test-id="add-body-field-button"
          value="Add Field"
          @click="addBodyField"
        />
      </div>
    </template>

    <template #footer>
      <IconButton
        :disabled="submitDisabled"
        button-class="btn-outline-dark"
        bootstrap-icon="boxes"
        value="Apply To <u>All</u> Trades"
        @click="applyToAllTradesClick"
      />

      <IconButton
        :disabled="submitDisabled"
        :button-class="!isSingleTrade ? 'btn-outline-dark' : 'btn-dark'"
        bootstrap-icon="box"
        value="Apply To <u>Opened</u> Trades"
        @click="applyToOpenedTradesClick"
      />

      <IconButton
        v-if="!isSingleTrade"
        :disabled="submitDisabled"
        button-class="btn-dark"
        bootstrap-icon="box-fill"
        value="Apply To <u>This</u> Trade"
        @click="applyToThisTradeClick"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import clone from "clone";
import {defaultSettings, RenderedTrade} from "~/models/rendered-trade";
import {useMapPairsStore} from "~/store/map-pairs";
import {useModalStore} from "~/store/modal";
import {useProfileStore} from "~/store/profile";
import {useRenderedTradesStore} from "~/store/rendered-trades";

const mapPairsStore = useMapPairsStore();
const modalStore = useModalStore();
const profileStore = useProfileStore();
const renderedTradesStore = useRenderedTradesStore();

const isSingleTrade = ref<boolean>(false);
const submitDisabled = ref<boolean>(false);
const trade = ref<RenderedTrade | null>(null);
const form = ref<RenderedTrade["settings"] | null>(null);

watch(() => form.value, () => selectActiveProfileIfSettingsChanged(), { deep: true });
watch(() => profileStore.selectedProfile, () => setFormValueFromSelectedProfile());

const onModalOpen = async () => {
  const tradeId = modalStore.arguments("TradeCustomization")?.tradeId as string | null;
  isSingleTrade.value = tradeId === null;
  submitDisabled.value = false;
  trade.value = tradeId ? renderedTradesStore.getTradeById(tradeId) : null;
  form.value = clone<RenderedTrade["settings"]>(trade?.value?.settings ?? defaultSettings);
  await nextTick();
  selectActiveProfile();
};

const addBodyField = () => {
  form.value!.bodyFields.push(null);
};

const moveBodyFieldUp = (index: number) => {
  if (index === 0) {
    return;
  }

  const bodyFields = form.value!.bodyFields;
  const temp = bodyFields[index - 1];
  bodyFields[index - 1] = bodyFields[index];
  bodyFields[index] = temp;
};

const moveBodyFieldDown = (index: number) => {
  const bodyFields = form.value!.bodyFields;
  if (index === bodyFields.length - 1) {
    return;
  }

  const temp = bodyFields[index + 1];
  bodyFields[index + 1] = bodyFields[index];
  bodyFields[index] = temp;
};

const removeBodyField = (index: number) => {
  form.value!.bodyFields.splice(index, 1);
};

const applyToAllTradesClick = () => {
  const allTrades = renderedTradesStore.renderedTrades;
  allTrades.forEach((trade) => {
    form.value.separatorColor = form.value.outlineColor;
    trade.settings = clone<RenderedTrade["settings"]>(form.value);
  });
  renderedTradesStore.update(allTrades);
  modalStore.close("TradeCustomization");
};

const applyToOpenedTradesClick = () => {
  const openedTrades = renderedTradesStore.renderedTrades.filter((trade) => trade.state.bubbleOpen);
  openedTrades.forEach((trade) => {
    form.value.separatorColor = form.value.outlineColor;
    trade.settings = clone<RenderedTrade["settings"]>(form.value);
  });
  renderedTradesStore.update(openedTrades);
  modalStore.close("TradeCustomization");
};

const applyToThisTradeClick = () => {
  const tradeToUpdate = clone<RenderedTrade>(trade.value);
  form.value.separatorColor = form.value.outlineColor;
  tradeToUpdate.settings = clone<RenderedTrade["settings"]>(form.value);
  renderedTradesStore.update([tradeToUpdate]);
  modalStore.close("TradeCustomization");
};

const selectActiveProfile = () => {
  const selectedProfile = profileStore.findBySettings(form.value!);
  profileStore.selectedProfile = selectedProfile;
};

const selectActiveProfileIfSettingsChanged = () => {
  if (JSON.stringify(profileStore.selectedProfile?.settings) !== JSON.stringify(form.value)) {
    selectActiveProfile();
  }
};

const setFormValueFromSelectedProfile = () => {
  if (profileStore.selectedProfile) {
    form.value = clone(profileStore.selectedProfile.settings);
  }
};
</script>
