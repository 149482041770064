import type {UserInterface} from "~/server/models/user";
import type {TODO} from "~/types/todo";
import {useAuthUser} from "./useAuthUser";

export const useAuth = () => {
    const authUser = useAuthUser();

    const setUser = (user: UserInterface) => {
        authUser.value = user;
    };

    const setCookie = (cookie: TODO) => {
        cookie.value = cookie;
    };

    const login = async (email: string, password: string, rememberMe: boolean) => {
        const data = await $fetch<{ user: UserInterface }>("/auth/login", {
            method: "POST",
            body: {
                email,
                password,
                rememberMe,
            },
        });

        setUser(data.user);

        return authUser;
    };

    const logout = async () => {
        const data = await $fetch<{ user: UserInterface }>("/auth/logout", {
            method: "POST",
        });

        setUser(data.user);
    };

    const me = async () => {
        if (!authUser.value) {
            try {
                const data = await $fetch<{ user: UserInterface }>("/auth/me", {
                    headers: useRequestHeaders(["cookie"]) as HeadersInit,
                });

                setUser(data.user);
            } catch (error) {
                setCookie(null);
            }
        }

        return authUser;
    };

    return {
        login,
        logout,
        me,
    };
};
