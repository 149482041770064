export const formatCurrency = (
    value: number | null,
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
    currency = "CAD"
): string => {
    if (!value) {
        return "—";
    }

    const formatter = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency,
        currencyDisplay: "narrowSymbol",
        minimumFractionDigits,
        maximumFractionDigits,
    });

    return formatter.format(value);
};

export const formatNumber = (
    value: number | null,
    maximumFractionDigits = 3,
    minimumFractionDigits = 0,
): string => {
    if (!value) {
        return "—";
    }

    const formatter = new Intl.NumberFormat("en-CA", {
        minimumFractionDigits,
        maximumFractionDigits,
    });

    return formatter.format(value);
};

export const formatPercentage = (
    value: number | null,
    maximumFractionDigits = 3,
    minimumFractionDigits = 0,
): string => {
    if (!value) {
        return "—";
    }

    const formatter = new Intl.NumberFormat("en-CA", {
        style: "percent",
        minimumFractionDigits,
        maximumFractionDigits,
    });

    return formatter.format(value);
};

export const isStringValidNumber = (value: string, allowEmpty = true): boolean => {
    if (allowEmpty && !value) {
        return true;
    }

    return /^\d+(\.\d+)?$/.test(value);
};

export const isStringValidPid = (value: string): boolean => {
    if (!value) {
        return false;
    }

    return /^\d{3}-\d{3}-\d{3}$/.test(value);
};

export const isValidPids = (pids: string[]|string): boolean => {
    if (!pids) {
        return false;
    }

    if (Array.isArray(pids)) {
        return pids.every(pid => isStringValidPid(pid));
    }

    return pids.split(",").every(pid => isStringValidPid(pid));
};
