<template>
  <div class="d-flex flex-row flex-nowrap input-group h-fit">
    <input
      type="number"
      class="form-control form-control-sm"
      :placeholder="placeholder ? `${placeholder}` : options.inputPlaceholder ? options.inputPlaceholder : ''"
      :step="options.inputNumberStep"
      :value="filterFormStore.filterForm[field][fallbackRangeType]"
      @input="handleInputChange"
    >
  </div>
</template>

<script setup lang="ts">
import {type TradeFieldsToOptions, type TradeOptions} from "~/utils/trade-fields";
import {RangeType} from "~/utils/range-type-helper";
import {useFilterFormStore} from "~/store/filter-form";

const filterFormStore = useFilterFormStore();
const props = defineProps<{
  field: keyof TradeFieldsToOptions;
  options: TradeOptions;
  rangeType: RangeType;
  placeholder?: string;
}>();

const fallbackRangeType = computed(() => {
  if (props.rangeType === RangeType.EQUAL_TO) {
    return RangeType.SMALLER_OR_EQUAL_THAN;
  }
  return props.rangeType;
});

function handleInputChange(event: Event) {
  const target = event.target as HTMLInputElement;
  const floatValue = parseFloat(target.value);
  if (props.rangeType === RangeType.EQUAL_TO) {
    filterFormStore.filterForm[props.field] = {
      [RangeType.SMALLER_OR_EQUAL_THAN]: floatValue,
      [RangeType.LARGER_OR_EQUAL_THAN]: floatValue,
    };
    return;
  }
  filterFormStore.filterForm[props.field][props.rangeType] = floatValue;
}

</script>