import clone from "clone";
import {
    defaultSettings, defaultState,
    RenderedTrade, WHISPER_STATUS,
} from "~/models/rendered-trade";
import type {TradeInterface} from "~/server/models/trade";
import type {Response} from "~/server/types/response";
import {useMapStore} from "~/store/map";
import {useMapPairsStore} from "~/store/map-pairs";
import {useToastStore} from "~/store/toast";
import type {TradeFilter} from "~/types/trade-filter";
import {getLimitedZoom} from "~/utils/zoom";

export const useRenderedTradesStore = defineStore("rendered-trades", {
    state: () => ({
        renderedTrades: [] as RenderedTrade[],
        lockTradeInputs: false,
    }),
    actions: {
        async render(filter: TradeFilter | null) {
            const mapStore = useMapStore();
            const toastStore = useToastStore();

            const loadingIndicator = useLoadingIndicator();
            this.lockTradeInputs = true;
            loadingIndicator.start();

            await this.clean();

            const response = await useFetch<Response<TradeInterface[]>>("/api/trades", {
                method: "POST",
                body: filter,
            });

            const trades = response.data.value.data;

            const tradesGroupedByPids: { [key: string]: RenderedTrade[]; } = {};
            trades.forEach(trade => {
                const state = clone(defaultState);
                const renderedTrade = new RenderedTrade(
                    trade._id,
                    trade.purchaser,
                    trade.pids,
                    trade.status,
                    trade.startDate,
                    trade.endDate,
                    trade.address,
                    trade.municipality,
                    trade.ncp,
                    trade.price,
                    trade.grossAcres,
                    trade.netAcres,
                    trade.propertyType,
                    trade.landUseDesignation,
                    trade.gfaModifier,
                    trade.densityType,
                    trade.notes,
                    trade.createdBy as string,
                    trade.insertedAt,
                    trade.grossSqft,
                    trade.netSqft,
                    trade.priceAcreGross,
                    trade.priceAcreNet,
                    trade.grossPsfl,
                    trade.netPsfl,
                    trade.units,
                    trade.fsr,
                    trade.psfb,
                    trade.pricePerDoor,
                    trade.gfa,
                    trade.upa,
                    trade.whisper,
                    trade.geoData,
                    false,
                    clone(defaultSettings),
                    state,
                );

                const tradePidsKey = trade.pids.join(",");
                if (!tradesGroupedByPids[tradePidsKey]) {
                    tradesGroupedByPids[tradePidsKey] = [];
                }
                tradesGroupedByPids[tradePidsKey].push(renderedTrade);
            });

            this.renderedTrades = this.getRenderedTradesWithShownWhispers(tradesGroupedByPids);
            this.lockTradeInputs = false;
            loadingIndicator.finish();

            mapStore.flyToVisibleTrades();

            if (this.renderedTrades.length === 0) {
                toastStore.addToast("No trades found for the selected filters", "warning");
            }
        },
        // TODO: In order to remove resetFilters parameter, we need to create filters store and call resetFilters action from there.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async clean(resetFilters: boolean = false) {
            this.resetRenderedTrades();
            const mapStore = useMapStore();
            mapStore.map.invalidateSize();
            mapStore.flyToDefault();
        },
        async resetRenderedTrades() {
            this.renderedTrades = [];
        },
        update(trades: RenderedTrade[]) {
            const loadingIndicator = useLoadingIndicator();
            loadingIndicator.start();
            this.lockTradeInputs = true;

            this.renderedTrades.forEach(async (trade) => {
                const updatedTrade = trades.find((item) => item._id === trade._id);
                if (!updatedTrade) {
                    return;
                }

                const originalTradeWithoutSettings = clone<RenderedTrade>(trade);
                delete originalTradeWithoutSettings.settings;

                trade.update(updatedTrade);

                const updatedTradeWithoutSettings = clone<RenderedTrade>(updatedTrade);
                delete updatedTradeWithoutSettings.settings;

                if (JSON.stringify(originalTradeWithoutSettings) !== JSON.stringify(updatedTradeWithoutSettings)) {
                    const fieldsToUpdate = Object.fromEntries(Object.entries(updatedTradeWithoutSettings).filter(([key, value]) => {
                        return JSON.stringify(originalTradeWithoutSettings[key]) !== JSON.stringify(value);
                    }));

                    if (Object.keys(fieldsToUpdate).length === 0) {
                        return;
                    }

                    const response = await useFetch<Response>(`/api/trades/${trade._id}`, {
                        method: "PATCH",
                        body: fieldsToUpdate,
                    });

                    const updatedTradeWithCalculatedFields = {
                        ...updatedTrade,
                        ...response?.data?.value?.data,
                    } as RenderedTrade;

                    trade.update(updatedTradeWithCalculatedFields);
                }

                this.lockTradeInputs = false;
                loadingIndicator.finish();
            });
        },
        async delete(trade: RenderedTrade) {
            const loadingIndicator = useLoadingIndicator();
            loadingIndicator.start();

            const mapPairsStore = useMapPairsStore();

            mapPairsStore.getPolygon(trade._id)?.remove();
            mapPairsStore.getMarker(trade._id)?.remove();
            mapPairsStore.getPopup(trade._id)?.remove();
            mapPairsStore.getPolyline(trade._id)?.remove();
            mapPairsStore.getAssemblyPolygons(trade._id).forEach((polygon) => polygon.remove());

            trade.deleted = true;

            loadingIndicator.finish();
        },
        hideMultipleTrades(trades: RenderedTrade[]) {
            trades.forEach((trade) => {
                const index = this.renderedTrades.findIndex((item) => item?._id === trade._id);
                this.renderedTrades[index].state.hidden = true;
            });
        },
        toggleVisibility(trade: RenderedTrade) {
            setTimeout(() => {
                const index = this.renderedTrades.findIndex((item) => item?._id === trade._id);
                this.renderedTrades[index].state.hidden = !this.renderedTrades[index].state.hidden;
            });
        },
        async handleTradeHiding(trade: RenderedTrade) {
            const tradesToHide = this.getTradesByExactPids(trade.pids);
            let tradesWithoutCurrent = tradesToHide.filter((item) => item._id !== trade._id);

            if (trade.status === WHISPER_STATUS && trade.state.hidden === false) {
                const originalTrade = tradesWithoutCurrent.find((item) => item.status !== WHISPER_STATUS);
                if (originalTrade) {
                    tradesWithoutCurrent = tradesWithoutCurrent.filter((item) => item._id !== originalTrade?._id);
                    this.toggleVisibility(originalTrade);
                }
            }
            this.hideMultipleTrades(tradesWithoutCurrent);
            this.toggleVisibility(trade);
        },
        async showOnlySpecifiedTrades(tradesToShow: RenderedTrade[]) {
            const tradesToShowIds = tradesToShow.map((item) => item._id);
            const tradesToHide = this.renderedTrades.filter((item) => !tradesToShowIds.includes(item._id));
            this.hideMultipleTrades(tradesToHide);
        },
        async updateBubbleZoom(trade: RenderedTrade, zoom: number | null = null) {
            await nextTick();
            const mapPairsStore = useMapPairsStore();

            const popup = mapPairsStore.getPopup(trade._id);
            if (!popup) {
                return;
            }

            const popupElement = popup["_container"] as HTMLElement;
            if (!popupElement) {
                return;
            }

            const classToRemove = [...popupElement.classList].find((className) => className.startsWith("bubble-zoom-"));
            popupElement?.classList.remove(classToRemove ?? "");

            if (zoom === null) {
                zoom = (trade.settings?.bubbleZoom ?? defaultSettings.bubbleZoom);
            }

            zoom = getLimitedZoom(zoom);

            const zoomString = zoom.toString().replace(".", "").padEnd(2, "0");
            popupElement?.classList.add(`bubble-zoom-${zoomString}`);
        },
        getRenderedTradesWithShownWhispers(tradesGroupedByPids: { [key: string]: RenderedTrade[]; }) {
            return Object.values(tradesGroupedByPids).flatMap((trades) => {
                let oneWhisperTradeVisible = false;
                return trades.map((trade) => {
                    if (trades.length === 1 || (trade.status === WHISPER_STATUS && oneWhisperTradeVisible === false)) {
                        trade.state.hidden = false;
                        oneWhisperTradeVisible = true;
                    }
                    return trade;
                });
            });
        },
        async refreshOpenBubbles() {
            const mapPairsStore = useMapPairsStore();
            for (const trade of this.renderedTrades) {
                if (trade.state.bubbleOpen) {
                    await nextTick();
                    const polygon = mapPairsStore.getPolygon(trade._id);
                    polygon?.fire("click");
                }
            }
        }
    },
    getters: {
        isEmpty(): boolean {
            return this.renderedTrades.length === 0;
        },
        getTradeById: (state) => (id: string): RenderedTrade | null => {
            return state.renderedTrades.find((item) => item._id === id) ?? null;
        },
        getTradesByExactPids: (state) => (pids: string[]): RenderedTrade[] => {
            return state.renderedTrades.filter((trade) =>
                trade.pids.every((pid) => pids.includes(pid)));
        },
    },
});
