<template>
  <div
    class="offcanvas offcanvas-end offcanvas-dark"
    :class="{'show': toastStore.panelOpened}"
    data-bs-scroll="true"
    tabindex="-1"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">
        Notifications
      </h5>

      <button
        type="button"
        class="btn-close"
        @click="toastStore.togglePanel()"
      />
    </div>

    <div class="offcanvas-body d-flex flex-column gap-2">
      <Toast
        v-for="(toast, index) in paginatedToasts"
        :key="index"
        :message="toast.message"
        :added-at="toast.addedAt"
        :type="toast.type"
        :index="index"
        :hideable="false"
        :show-time-ago="true"
        class="w-100"
      />

      <div
        v-if="!toastStore.toasts.length"
        class="text-center"
      >
        No notifications yet.
      </div>

      <div class="d-flex justify-content-center my-4">
        <IconButton
          :disabled="paginatedToasts.length >= toastStore.toasts.length"
          button-class="btn-dark"
          bootstrap-icon="arrow-down-short"
          value="Load More"
          @click="loadMore"
        />
      </div>
    </div>
  </div>

  <div
    v-if="toastStore.panelOpened"
    class="offcanvas-backdrop fade show"
    @click="toastStore.togglePanel()"
  />
</template>

<script setup lang="ts">
import {useToastStore} from "~/store/toast";

const toastStore = useToastStore();

const itemsPerPage = 5;
const loadedItems = ref(5);
const paginatedToasts = computed(() => {
  return toastStore.toasts.slice(0, loadedItems.value);
});

const loadMore = () => {
  loadedItems.value += itemsPerPage;
};
</script>
