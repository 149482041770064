<template>
  <input
    v-model="filterFormStore.filterForm[field]"
    :type="options.inputType"
    :placeholder="placeholder ? placeholder : options.inputPlaceholder ? options.inputPlaceholder : ''"
    class="form-control form-control-sm"
  >
</template>

<script setup lang="ts">
import type {TradeFieldsToOptions, TradeOptions} from "~/utils/trade-fields";
import {useFilterFormStore} from "~/store/filter-form";

const filterFormStore = useFilterFormStore();
defineProps<{
  field: keyof TradeFieldsToOptions;
  options: TradeOptions;
  placeholder?: string;
}>();
</script>
