<template>
  <input
    v-model="filterFormStore.filterForm[field]"
    :type="type"
    :placeholder="placeholder ? placeholder : options.inputPlaceholder ? options.inputPlaceholder : ''"
    class="form-control form-control-sm"
    @focus="inputFocus"
  >
</template>

<script setup lang="ts">
import type {TradeFieldsToOptions, TradeOptions} from "~/utils/trade-fields";
import {useFilterFormStore} from "~/store/filter-form";

const filterFormStore = useFilterFormStore();
defineProps<{
  field: keyof TradeFieldsToOptions;
  options: TradeOptions;
  placeholder?: string;
}>();

const type = ref("text");

const inputFocus = async (event: FocusEvent) => {
  type.value = "date";

  await nextTick();
  const dateInputElement = event.target as HTMLInputElement;
  dateInputElement.showPicker();
};
</script>
