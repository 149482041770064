import { defineStore } from "pinia";
import type { FilterForm, FilterFormValue, RangeFilterValue } from "~/server/models/filter-form";
import { RangeType } from "~/utils/range-type-helper";
import { tradeFieldsToOptions } from "~/utils/trade-fields";

export const useFilterFormStore = defineStore("filter", {
    state: () => ({
        filterForm: initializeFilterForm(),
    }),

    actions: {
        clearFilter(field: string) {
            this.filterForm[field] = initializeFilterValue(tradeFieldsToOptions[field].inputType);
        },
        clearAllFilters() {
            this.filterForm = initializeFilterForm();
        },
    },

    getters: {
        getAllFilters: (state) => {
            return state.filterForm;
        }
    },

});

function initializeFilterForm(): FilterForm {
    const form: FilterForm = {};
    for (const [key, options] of Object.entries(tradeFieldsToOptions)) {
        form[key] = initializeFilterValue(options.inputType);
    }
    return form;
}

function initializeFilterValue(inputType: string): FilterFormValue {
    if (inputType === "number" || inputType === "single-number") {
        return {
            [RangeType.LARGER_OR_EQUAL_THAN]: null,
            [RangeType.SMALLER_OR_EQUAL_THAN]: null
        } as RangeFilterValue;
    }
    return null;
}

function handleEqualRangeType() {
    const filterFormStore = useFilterFormStore();

    filterFormStore.$subscribe((mutation, state) => {
        console.log('Filter store updated:', {
            type: mutation.type,
            changedFilter: mutation.payload,
            newState: JSON.parse(JSON.stringify(state.filterForm))
        });
    });
}

