<template>
  <Modal
    name="ProjectSave"
    size="md"
    :centered="true"
    @open="onModalOpen"
  >
    <template #title>
      Project Save
    </template>

    <template #body>
      Details saved to the project:
      <ul>
        <li>Map imagery layer</li>
        <li>Map zoom and position</li>
        <li>Trades with their customizations</li>
        <li>Only trades that are visible on the viewport will be saved</li>
      </ul>

      <label>Project Name</label>
      <input
        v-model="projectName"
        required
        class="form-control form-control-sm"
        @keydown.enter="onSaveProject"
      >
    </template>

    <template #footer>
      <IconButton
        button-class="btn-dark"
        bootstrap-icon="box-seam-fill"
        type="submit"
        value="Save Project"
        @click="onSaveProject"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import type {ProjectInterface} from "~/server/models/project";
import type {ProjectTradeInterface} from "~/server/models/project-trade";
import {useModalStore} from "~/store/modal";
import {useProjectStore} from "~/store/project";
import {useMapStore} from "~/store/map";
import {useRenderedTradesStore} from "~/store/rendered-trades";
import {useToastStore} from "~/store/toast";

const renderedTradesStore = useRenderedTradesStore();
const projectStore = useProjectStore();
const toastStore = useToastStore();
const modalStore = useModalStore();
const mapStore = useMapStore();

const loadingIndicator = useLoadingIndicator();

const projectName = ref("");
const project = ref<ProjectInterface>();

const onModalOpen = () => {
  const visibleRenderedTrades = renderedTradesStore.renderedTrades.filter((trade) => mapStore.isTradeInViewport(trade));

  const projectTrades: ProjectTradeInterface[] = visibleRenderedTrades.map((trade) => ({
    trade: trade._id,
    settings: trade.settings,
    state: trade.state,
  }));

  project.value = {
    name: projectName.value,
    mapTileLayer: mapStore.currentTileLayer.name,
    mapZoom: mapStore.zoom,
    mapCenter: mapStore.center,
    projectTrades: projectTrades,
  };
};

const onSaveProject = async () => {
  project.value.name = projectName.value;

  if (projectName.value.length === 0) {
    return;
  }

  loadingIndicator.start();
  projectStore.save(project.value);

  projectName.value = "";
  modalStore.close("ProjectSave");
  toastStore.addToast("Project saved", "success");
  loadingIndicator.finish();
};
</script>
