import type {RenderedTrade} from "~/models/rendered-trade";
import type {TradeInterface} from "~/server/models/trade";
import {formatDateTimeString, isStringValidDate} from "~/utils/date-time-formatter";
import {municipalities} from "~/utils/municipalities";
import {formatCurrency, formatNumber, formatPercentage, isStringValidNumber, isValidPids} from "~/utils/number-formatter";

export type Validation = {
    valid: boolean;
    message?: string;
};

// REFACTOR: Rename "number" to something like "number-range" and remake this entire thing to enum
export type InputType = "text" | "number" | "textarea" | "date" | "select" | "single-number";

export type TradeOptions = {
    readableName: string;
    tableCellWidth: number;
    inputType: InputType;
    inQuickDropdownFilter?: boolean;
    inputNumberStep?: number;
    inputPlaceholder?: string;
    defaultPopupPosition?: number;
    formatter?: <T>(value: T) => string;
    validator?: <T>(value: T) => Validation;
    valueToSave?: <T>(value: T) => unknown; // TODO: Is this needed and working?
    calculatedField?: boolean;
    selectOptions?: string[];
}

export type TradeFieldsToOptions = {
    [key: string]: TradeOptions;
};

export const tradeFieldsToOptions: TradeFieldsToOptions = {
    "pids": {
        readableName: "PIDs",
        inputType: "text",
        inQuickDropdownFilter: false,
        tableCellWidth: 450,
        inputPlaceholder: "000-000-000, 111-222-333",
        formatter: (value) => {
            let pids;
            if (Array.isArray(value)) {
                pids = value;
            } else {
                pids = (value as string).split(",").map((pid) => pid.trim());
            }

            pids = [...new Set(pids)];
            return pids.join(", ");
        },
        validator: (value) => ({
            valid: isValidPids(value as string[]),
            message: `Provided input "${value ?? ""}" is not valid PID(s). Please use comma separated PIDs in format 000-000-000.`,
        }),
        valueToSave: (value) => {
            if (!value) {
                return [];
            }

            if (Array.isArray(value)) {
                return [...new Set(value)];
            }

            let pids = (value as string).split(",");
            pids = pids.map((pid) => pid.trim());
            pids = [...new Set(pids)];

            return pids;
        },
    },
    "purchaser": {
        readableName: "Purchaser",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 400,
        validator: (value) => ({
            valid: value !== "",
            message: "Please select a purchaser.",
        }),
    },
    "propertyType": {
        readableName: "Property Type",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 200,
    },
    "startDate": {
        readableName: "Start Date",
        tableCellWidth: 100,
        defaultPopupPosition: 4,
        inputPlaceholder: "YYYY-MM-DD",
        inputType: "date",
        inQuickDropdownFilter: false,
        formatter: (value) => formatDateTimeString(value as string, false),
        validator: (value) => ({
            valid: isStringValidDate(value as string),
            message: `Provided input "${value ?? ""}" is not a valid date. Please use the format YYYY-MM-DD.`,
        }),
    },
    "endDate": {
        readableName: "End Date",
        tableCellWidth: 100,
        defaultPopupPosition: 5,
        inputPlaceholder: "YYYY-MM-DD",
        inputType: "date",
        inQuickDropdownFilter: false,
        formatter: (value) => formatDateTimeString(value as string, false),
        validator: (value) => ({
            valid: isStringValidDate(value as string),
            message: `Provided input "${value ?? ""}" is not a valid date. Please use the format YYYY-MM-DD.`,
        }),
    },
    "address": {
        readableName: "Address",
        inputType: "text",
        inQuickDropdownFilter: false,
        tableCellWidth: 400,
        defaultPopupPosition: 0,
    },
    "municipality": {
        readableName: "Municipality",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 130,
        selectOptions: municipalities,
    },
    "ncp": {
        readableName: "NCP",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 180,
    },
    "price": {
        readableName: "Price",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        defaultPopupPosition: 1,
        formatter: (value) => formatCurrency(value as number, 0),
        validator: (value) => ({
            valid: isStringValidNumber(value as string),
            message: `Provided input "${value ?? ""}" is not a valid number.`,
        }),
    },
    "grossAcres": {
        readableName: "Gross Acres",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatNumber(value as number, 2, 2),
        validator: (value) => ({
            valid: isStringValidNumber(value as string),
            message: `Provided input "${value ?? ""}" is not a valid number.`,
        }),
    },
    "netAcres": {
        readableName: "Net Acres",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatNumber(value as number, 2, 2),
        validator: (value) => ({
            valid: isStringValidNumber(value as string),
            message: `Provided input "${value ?? ""}" is not a valid number.`,
        }),
    },
    "grossSqft": {
        readableName: "Gross Sq.ft",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: false,
        defaultPopupPosition: 3,
        formatter: (value) => formatNumber(value as number, 0),
        calculatedField: true,
    },
    "netSqft": {
        readableName: "Net Sq.ft",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: false,
        formatter: (value) => formatNumber(value as number, 0),
        calculatedField: true,
    },
    "priceAcreGross": {
        readableName: "Price/Acre (Gross)",
        tableCellWidth: 150,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatCurrency(value as number, 0),
        calculatedField: true,
    },
    "priceAcreNet": {
        readableName: "Price/Acre (Net)",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatCurrency(value as number, 0),
        calculatedField: true,
    },
    "grossPsfl": {
        readableName: "Gross PSFL",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatCurrency(value as number, 0),
        calculatedField: true,
    },
    "netPsfl": {
        readableName: "Net PSFL",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatCurrency(value as number, 0),
        calculatedField: true,
    },
    "psfb": {
        readableName: "PSFB",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        defaultPopupPosition: 2,
        formatter: (value) => formatCurrency(value as number, 0),
        calculatedField: true,
    },
    "pricePerDoor": {
        readableName: "$/Door",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: true,
        formatter: (value) => formatCurrency(value as number, 0),
        calculatedField: true,
    },
    "status": {
        readableName: "Status",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 100,
    },
    "landUseDesignation": {
        readableName: "Land Use Designation",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 200,
    },
    "fsr": {
        readableName: "FSR",
        inputType: "number",
        inQuickDropdownFilter: false,
        tableCellWidth: 130,
        formatter: (value) => formatNumber(value as number, 2, 1),
    },
    "units": {
        readableName: "# Doors",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: false,
        formatter: (value) => formatNumber(value as number, 0),
        validator: (value) => ({
            valid: isStringValidNumber(value as string),
            message: `Provided input "${value ?? ""}" is not a valid number.`,
        }),
    },
    "gfaModifier": {
        readableName: "GFA Modifier",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: false,
        inputNumberStep: 0.1,
        formatter: (value) => formatPercentage(value as number, 1, 0),
        validator: (value) => ({
            valid: isStringValidNumber(value as string),
            message: `Provided input "${value ?? ""}" is not a valid modifier. Please use decimal format.`,
        }),
    },
    "gfa": {
        readableName: "GFA",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: false,
        formatter: (value) => formatNumber(value as number, 0),
        calculatedField: true,
    },
    "upa": {
        readableName: "UPA Net",
        tableCellWidth: 130,
        inputType: "number",
        inQuickDropdownFilter: false,
        formatter: (value) => formatNumber(value as number, 1, 1),
        calculatedField: true,
    },
    "densityType": {
        readableName: "Density Type",
        inputType: "select",
        inQuickDropdownFilter: false,
        tableCellWidth: 130,
        selectOptions: [
            "UPA",
            "FSR",
        ],
        validator: (value) => ({
            valid: ["UPA", "FSR"].includes(value as string),
            message: `Provided input "${value ?? ""}" is not a valid density type, must be one of "UPA" or "FSR".`,
        }),
    },
    "notes": {
        readableName: "Notes",
        inputType: "textarea",
        inQuickDropdownFilter: false,
        tableCellWidth: 500,
    },
};

export const getFormattedValue = (field: keyof TradeInterface, trade: RenderedTrade | TradeInterface): string => {
    return getFormattedValueFromValue(field, trade[field]);
};

export const getFormattedValueFromValue = (field: keyof TradeInterface, value: unknown): string => {
    const options = tradeFieldsToOptions[field as keyof TradeFieldsToOptions];

    if (!value) {
        return "—";
    }

    if (options && options.formatter) {
        return options.formatter(value);
    }

    if (String(value).length === 0) {
        return "—";
    }

    return String(value);
};


export const getValueValidation = (field: keyof TradeInterface, value: unknown): Validation => {
    const options = tradeFieldsToOptions[field as keyof TradeFieldsToOptions];

    // TODO: Fix PIDs being dictionary, not array, looks like wrongly got from DB
    if (options && options.validator) {
        return options.validator(value);
    }

    return {
        valid: true,
    };
};

export const getValueToSave = (field: keyof TradeInterface, value: unknown): unknown => {
    const options = tradeFieldsToOptions[field as keyof TradeFieldsToOptions];

    if (options && options.valueToSave) {
        return options.valueToSave(value);
    }

    return value;
};
