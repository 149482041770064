<template>
  <div class="d-flex gap-2">
    <input
      v-if="savingNewProfile"
      ref="newProfileNameInput"
      v-model="newProfileName"
      type="text"
      placeholder="Enter a new profile name"
      class="form-control form-control-sm"
      :class="{'is-invalid': newProfileError}"
      @keyup.enter="save"
    >
    <VueMultiselect
      v-if="!savingNewProfile"
      v-model="profileStore.selectedProfile"
      :options="profileStore.profiles"
      :allow-empty="false"
      deselect-label="Selected"
      select-label=""
      placeholder="Select a profile"
      :custom-label="profile => profile.name"
    />
    <IconButton
      button-class="btn-dark"
      bootstrap-icon="floppy-fill"
      value="Save"
      :disabled="saveDisabled"
      @click="save"
    />
    <IconButton
      v-if="!savingNewProfile"
      button-class="btn-danger"
      bootstrap-icon="trash3"
      :disabled="deleteDisabled"
      @click="profileStore.delete(profileStore.selectedProfile)"
    />
  </div>
</template>

<script setup lang="ts">
import VueMultiselect from "vue-multiselect";
import {defaultSettings, type RenderedTradeSettings} from "~/models/rendered-trade";
import {useProfileStore} from "~/store/profile";

const props = defineProps<{
  settings?: RenderedTradeSettings;
}>();

const profileStore = useProfileStore();

const saveDisabled = computed<boolean>(() => JSON.stringify(profileStore.selectedProfile?.settings) === JSON.stringify(defaultSettings));
const deleteDisabled = computed<boolean>(() => (profileStore.selectedProfile?._id ?? null) === null);

const newProfileNameInput = ref<HTMLInputElement | null>(null);
const savingNewProfile = ref<boolean>(false);
const newProfileName = ref<string>("");
const newProfileError = ref<boolean | null>(false);

const save = async () => {
  if (!savingNewProfile.value) {
    savingNewProfile.value = true;
    await nextTick();
    newProfileNameInput.value?.focus();
    return;
  }

  if (newProfileName.value.length === 0) {
    newProfileError.value = true;
    return;
  }

  profileStore.save({
    name: newProfileName.value,
    settings: props.settings,
  });

  savingNewProfile.value = false;
  newProfileName.value = "";
  newProfileError.value = false;
};
</script>
