import type {RenderedTradeSettings} from "~/models/rendered-trade";
import type {ProfileInterface} from "~/server/models/profile";

export const useProfileStore = defineStore("profile", {
    state: () => ({
        selectedProfile: null as ProfileInterface | null,
        profiles: [] as ProfileInterface[],
    }),
    actions: {
        async populate() {
            this.profiles = [];

            const response = await useFetch("/api/profiles", {
                method: "GET",
            });

            this.profiles = response?.data?.value?.data as ProfileInterface[];
        },
        async save(profile: Partial<ProfileInterface>) {
            const response = await useFetch("/api/profiles", {
                method: "POST",
                body: profile,
            });

            const newProfile = response?.data?.value?.data as ProfileInterface;
            this.profiles.push(newProfile);

            this.selectedProfile = newProfile;
        },
        async delete(profile: ProfileInterface) {
            await useFetch(`/api/profiles/${profile._id}`, {
                method: "DELETE",
            });

            const index = this.profiles.findIndex((p) => p._id === profile._id);
            this.profiles.splice(index, 1);

            this.selectedProfile = this.profiles[0];
        },
    },
    getters: {
        findBySettings() {
            return (settings: RenderedTradeSettings) => {
                return this.profiles.find((profile) => {
                    return JSON.stringify(profile.settings) === JSON.stringify(settings);
                }) ?? null;
            };
        }
    }
});
